import Rebase from "re-base";
import firebase from "firebase";

const firebaseApp = firebase.initializeApp({
  apiKey: "AIzaSyBFfX1-2bM_sl6u9qRuRHl9iL73zhQGoxs",
  authDomain: "catch-of-the-day-vestal.firebaseapp.com",
  databaseURL: "https://catch-of-the-day-vestal.firebaseio.com",
  appId: "1:165120850080:web:0525830ef3c2ddfd"
});

const base = Rebase.createClass(firebaseApp.database());

// this is a named export
export { firebaseApp };

// this is a default export

export default base;
